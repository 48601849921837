import "@hotwired/turbo-rails"

import { Application } from "@hotwired/stimulus"
import { registerControllers } from 'stimulus-vite-helpers'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

const controllers = import.meta.globEager('../controllers/*_controller.js')
registerControllers(application, controllers)
